<template>
    <div class="container xl">
        <div class="not-found-section">
                    <div class="container">
                        <h1>404</h1>
                        <p>
                            Sorry, the page you were looking for doesn't exit.
                        </p>
                        <p><a :href="marketingSiteUrl" class="go-back">Back to Home</a></p>
                    </div>
                </div>
    </div>
</template>

<script>

export default {
    name: "NotFoundView",
    data() {
        return {
            marketingSiteUrl : process.env.VUE_APP_MARKETING_SITE_URL
        }
    },
    mounted() {
        console.log("404");
    },
};
</script>


<style lang="scss">
.not-found-section {
    text-align: center;
    padding: 100px 20px;

    h1 {
        text-shadow: 0 2px 30px rgba(157, 115, 221, 0.43);
        background-image: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0.3),
                rgba(0, 0, 0, 0.3)
            ),
            linear-gradient(257deg, #725bba 89%, #7c4283 6%);
        font-size: 200px;
        font-weight: 600;
        line-height: 1.62;
        text-align: center;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 20px;
    }

    p {
        font-size: 20px;
        margin-bottom: 0px;
    }

    a {
        color: #f64b3d;
        display: inline-block;

        &:hover {
            opacity: 0.8;
        }

        &.go-back {
            margin-top: 30px;
        }
    }
}

@media (max-width: 640px) {
    .not-found-section {
        h1 {
            font-size: 100px;
        }

        p {
            font-size: 18px;
        }
    }
}
</style>

